<template>
    <div>
      <vue-good-table
        :pagination-options="{
            enabled: true,
            perPage: 5,
            perPageDropdown: [5, 10],
        }"
        :columns="columns"
        :rows="rows"/>
    </div>
  </template>
  
<script>
export default {
    props: ['rows'],
    data(){
        return {
            columns: [
            {
                label: 'Request',
                field: 'request',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'Text',
                field: 'text',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'File',
                field: 'file',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'Data',
                field: 'data',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'Message Type',
                field: 'message_type',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'Hold',
                field: 'hold',
                filterOptions: {
                    enabled: true,
                },
            },
            {
                label: 'is Sensitive',
                field: 'sensitive',
                filterOptions: {
                    enabled: true,
                },
            },
            ],
        };
    },
  };
  </script>
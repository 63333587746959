<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Tambah Autoreply</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="saveAutoreply">
                                <label> Pengirim </label>
                                <select v-model="form.device_id" class="form-control" required>
                                    <option value="" selected disabled>== PILIH DEVICE ==</option>
                                    <option v-for="d in device" :key="d.id" :value="d.device_id">
                                        {{ d.name + " (" + d.status + ")"}}</option>
                                </select>
                                <br>
                                <label>Request Keyword</label>
                                <input type="text" name="" v-model="form.request" class="form-control"
                                    placeholder="Selamat Pagi | Selamat Siang | Selamat Malam" required>
                                <br>
                                <i>Pisahkan dengan | (vertical pipe) untuk multiple keyword</i>
                                <br>
                                <input name="sensitive" class="checkbox" v-model="form.sensitive" type="checkbox"
                                    id="check-1">
                                <label for="check-1">&nbsp;Case Sensitive</label>
                                <br>
                                <br>
                                <label for="">Jenis Response</label>
                                <select v-model="form.message_type" class="form-control">
                                    <option value="text">Text / Media</option>
                                    <!-- <option value="button">Button</option>
                                    <option value="list">List Message</option> -->
                                    <option value="loc">Location Message</option>
                                    <option value="poll">Poll</option>
                                </select>
                                <br>
                                <div v-if="form.message_type == 'text'">
                                    <label for="">Response</label>
                                    <CustomTextArea v-model="form.text" />
                                    <i>- Anda bisa panggil nama whatsapp menggunakan [[NAME]] </i>
                                    <br>
                                    <br>
                                    <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
                                    <input ref="file" type="file" @change="onFileChange" class="form-control">
                                </div>
                                <div v-else-if="form.message_type == 'button'" class="card thick-border">
                                    <div class="card-body">
                                        <h5 style="text-align: center;">BUTTON RESPONSE</h5>
                                        <label for="">Teks Button</label>
                                        <CustomTextArea v-model="form.text"></CustomTextArea>
                                        <i>- Anda bisa panggil nama whatsapp menggunakan [[NAME]] </i>
                                        <br>
                                        <br>
                                        <label> Gambar (jpg, png) </label>
                                        <input ref="file" type="file" @change="onFileChange" accept=".jpg,.jpeg,.png"
                                            class="form-control">
                                        <br>
                                        <button type="button" class="btn btn-primary btn-sm" v-on:click="addButton"
                                            style="margin:5px">Tambah Button</button>
                                        <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteButton"
                                            style="margin:5px">Hapus Button</button>

                                        <br>
                                        <div v-for="btn in button" v-bind:key="btn.id">
                                            <br>
                                            <div class="card thick-border">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <label for="">Tipe Button</label>
                                                            <select v-model="btn.type" class="form-control">
                                                                <option value="quickReplyButton">Quick Reply (Tulisan
                                                                    Biasa)</option>
                                                                <option value="urlButton">URL</option>
                                                                <option value="callButton">Call Nomor Telepon</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div>
                                                                <label for="">Display Text (Tulisan Pada Button)</label>
                                                                <input type="text" class="form-control"
                                                                    v-model="btn.display_text" required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div v-if="btn.type == 'urlButton'">
                                                        <label for="">URL</label>
                                                        <input type="text" class="form-control" v-model="btn.url"
                                                            placeholder="https://" required>
                                                    </div>
                                                    <div v-else-if="btn.type == 'callButton'">
                                                        <label for="">Nomor Telepon</label>
                                                        <input type="text" class="form-control"
                                                            v-model="btn.phoneNumber" placeholder="08xxxxxxxx" required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <label for="">Footer</label>
                                        <input type="text" v-model="form.footer" placeholder="Footer"
                                            class="form-control">
                                    </div>
                                    <div style="margin: 5px">
                                        <i>* Gambar hanya bisa tampil jika semua button berjenis Quick Reply</i>
                                        <br>
                                        <i>* Android Menampilkan Maksimal 3 Button</i>
                                        <br>
                                        <i>* Whatsapp Web Menampilkan Maksimal 5 Button</i>
                                        <br>
                                        <i>* IOS Unlimited Button</i>
                                    </div>
                                </div>
                                <div v-else-if="form.message_type == 'list'" class="card thick-border">
                                    <div class="card-body">
                                        <h5 style="text-align: center;">LIST MESSAGE</h5>
                                        <br>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label> File (docx, xlsx, pdf, jpg, png, mp4) [Akan Dikirimkan Secara
                                                    Terpisah]</label>
                                                <input ref="file" type="file" @change="onFileChange"
                                                    accept=".jpg,.jpeg,.png" class="form-control">
                                                <br>
                                                <label for="">Judul List Message</label>
                                                <input type="text" class="form-control" v-model="list.title"
                                                    placeholder="Title" required>
                                                <br>
                                                <label for="">Deskripsi List Message</label>
                                                <CustomTextArea v-model="form.text"></CustomTextArea>
                                                <i>- Anda bisa panggil nama whatsapp menggunakan [[NAME]] </i>
                                                <br>
                                                <br>
                                                <label for="">Button Text</label>
                                                <input type="text" class="form-control" v-model="list.buttonText"
                                                    placeholder="Klik Disini" required>
                                                <br>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    v-on:click="addListSection" style="margin:5px">Tambah
                                                    Pilihan</button>
                                                <button type="button" class="btn btn-danger btn-sm"
                                                    v-on:click="deleteListSection" style="margin:5px">Hapus
                                                    Pilihan</button>

                                                <br>
                                                <div v-for="ls in list.sections" v-bind:key="ls.id">
                                                    <br>
                                                    <div class="card thick-border">
                                                        <div class="card-body">
                                                            <label for="">Judul Pilihan</label>
                                                            <input type="text" class="form-control" v-model="ls.title"
                                                                placeholder="Title" required>
                                                            <br>
                                                            <label for="">Deskripsi Pilihan</label>
                                                            <CustomTextArea v-model="ls.description"></CustomTextArea>
                                                            <!-- <input type="text" class="form-control" v-model="ls.description" placeholder="Description" required> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <img style="padding:15px" src="@/assets/img/listmsg/1.png" />
                                                <img style="padding:15px" src="@/assets/img/listmsg/2.png"
                                                    width="371px" />

                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin: 5px">
                                        <i>* File / Gambar akan dikirimkan terlebih dahulu (secara terpsisah)</i>
                                    </div>
                                </div>
                                <div v-else-if="form.message_type == 'loc'">
                                    <label for="">Latitude</label>
                                    <input type="text" v-model="form.lat" class="form-control">
                                    <br>
                                    <label for="">Longitude</label>
                                    <input type="text" v-model="form.long" class="form-control">
                                    <br>
                                </div>
                                <div v-else-if="form.message_type == 'poll'" class="card thick-border">
                                    <div class="card-body">
                                        <h5 style="text-align: center;">Poll Message</h5>
                                        <label for="">Pertanyaan</label>
                                        <CustomTextArea v-model="form.question"></CustomTextArea>
                                        <br>
                                        <button type="button" class="btn btn-primary btn-sm" v-on:click="addAnswer"
                                            style="margin:5px">Tambah Jawaban</button>
                                        <button type="button" class="btn btn-danger btn-sm" v-on:click="deleteAnswer"
                                            style="margin:5px">Hapus Jawaban</button>
                    
                                        <br>
                                        <div v-for="btn, idx in answers" v-bind:key="idx">
                                            <input type="text" class="form-control mt-2" v-model="answers[idx]" :placeholder="'Jawaban ' + (idx+1)" required>
                                        </div>
                                        <br>
                                        <label for="">Maksimal Jawaban</label>
                                        <input type="number" v-model="form.max_answer" placeholder="1" class="form-control">
                                    </div>
                                </div>
                                <br>
                                <!-- <label for="">Hold Autoreply (Detik)</label>   
                                <input type="number" class="form-control" v-model="form.hold">
                                <br>
                                <b>* Berfungsi Untuk Menahan Respon Autoreply Selama x Detik Dari Respon Autoreply
                                    Terakhir</b>
                                <br>
                                <b>* Setting 0 Untuk Menonaktifkan Hold Autoreply</b>
                                <br>
                                <b>* Shortcut : <a class="pointer" v-on:click="setHold(1)">1 Jam</a> | <a class="pointer" v-on:click="setHold(2)">1 Hari</a> | <a class="pointer" v-on:click="setHold(3)">1 Minggu</a> | <a class="pointer" v-on:click="setHold(4)">1 Bulan</a> | <a class="pointer" v-on:click="setHold(5)">1 Tahun</a></b>
                                <br> -->
                                <div class="button-group d-flex pt-25">
                                    <button type="submit"
                                        class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                        simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            CustomTextArea,
        },
        data() {
            return {
                device: [],
                form: {
                    device_id: "",
                    source: "",
                    request: "",
                    sensitive: false,
                    message_type: "text",
                    text: "",
                    file: "",
                    max_text_length: 100,
                    footer: "",
                    hold: 0,
                    max_answer: 0,
                },
                answers: [],
                answer_index: 0,

                button: [],
                button_index: 0,
                list: {
                    title: "",
                    buttonText: "",
                    sections: [],
                    section_index: 0
                },
            };
        },
        mounted() {
            this.getDevice({
                status: "all"
            })
        },
        methods: {
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            onFileChange(e) {
                const selectedFile = e.target.files[0];
                this.form.file = selectedFile;
            },
            addAnswer() {
                this.answers.push("")

                this.answer_index += 1

                this.$toast.success("Tambah Answer Sukses");
            },
            deleteAnswer() {
                if (this.answer_index > 0) {
                    this.answers.splice(-1)
                    this.answer_index -= 1

                    this.$toast.success("Hapus Answer Sukses");
                }
            },
            addButton() {
                this.button.push({
                    type: "quickReplyButton",
                    display_text: "",
                    url: "",
                    phoneNumber: "",
                    id: "id-" + (this.button_index + 1)
                })

                this.button_index += 1

                this.$toast.success("Tambah Button Sukses");
            },
            deleteButton() {
                if (this.button_index > 0) {
                    this.button.splice(-1)
                    this.button_index -= 1

                    this.$toast.success("Hapus Button Sukses");
                }
            },
            addListSection() {
                this.list.sections.push({
                    title: "",
                    description: "",
                    id: "id-" + (this.list.section_index + 1)
                })

                this.list.section_index += 1

                this.$toast.success("Tambah Pilihan Sukses");
            },
            deleteListSection() {
                if (this.list.section_index > 0) {
                    this.list.sections.splice(-1)
                    this.list.section_index -= 1

                    this.$toast.success("Hapus Pilihan Sukses");
                }
            },
            setHold(num){
                if(num == 1){
                    this.form.hold = 60 * 60
                }else if(num == 2){
                    this.form.hold = 60 * 60 * 24
                }else if(num == 3){
                    this.form.hold = 60 * 60 * 24 * 7
                }else if(num == 4){
                    this.form.hold = 60 * 60 * 24 * 30
                }else if(num == 5){
                    this.form.hold = 60 * 60 * 24 * 365
                }
            },
            setPayload() {
                let payload = {
                    device_id: this.form.device_id,
                    request: this.form.request,
                    message_type: this.form.message_type,
                    sensitive: this.form.sensitive ? 1 : 0,
                    text: this.form.text,
                    file: this.form.file,
                    hold: parseInt(this.form.hold)
                }

                if (this.form.message_type == "button") {
                    payload.button = []
                    payload.footer = this.form.footer

                    for (let i = 0; i < this.button.length; i++) {
                        let button_to_push = {
                            button_id: this.button[i].id.split("-")[1],
                            button_type: this.button[i].type,
                            display_text: this.button[i].display_text,
                        }

                        if (this.button[i].type == "urlButton") {
                            button_to_push.button_action = this.button[i].url
                        } else if (this.button[i].type == "callButton") {
                            button_to_push.button_action = this.button[i].phoneNumber
                        }

                        payload.button.push(button_to_push)
                    }
                } else if (this.form.message_type == "list") {
                    payload.list = {
                        title: this.list.title,
                        buttonText: this.list.buttonText,
                        sections: this.list.sections
                    }
                } else if (this.form.message_type == "loc") {
                    payload.lat = this.form.lat
                    payload.long = this.form.long
                    payload.text = `[LocationMessage](${this.form.lat},${this.form.long})`
                } else if (this.form.message_type == "poll") {
                    payload.question = this.form.question
                    payload.answers = this.answers
                    payload.max_answer = this.form.max_answer
                    payload.text = `[PollMessage][${this.form.question}][${this.answers.join('~')}][${this.form.max_answer}]`
                }

                return payload
            },
            async saveAutoreply() {
                try {
                    const formData = new FormData();

                    if (this.form.file) {
                        formData.append('file', this.form.file)
                        let response = await this.$store.dispatch('cdn_upload', formData)
                        this.form.file = response.data.data.url
                    }

                    let payload = await this.setPayload()

                    let response = await this.$store.dispatch('autoreply_create', payload)
                    this.$toast.success(response.data.message);
                    this.$router.push("/autoreply")
                } catch (err) {
                    this.$toast.error("SERVER ERROR 500");
                }
            }
        },
    };
</script>

<style scoped>
    .thick-border {
        border: 2px solid #6c6c6c !important;
    }

    .pointer{
        cursor: pointer;
    }
</style>
<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Autoreply
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <!-- <button v-on:click="toggleModal('importExport')" class="btn px-15 btn-success text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Import / Export Autoreply
                            </button> -->
                            <router-link to="/autoreply/add" style="display: inline-flex; margin: 5px">
                                <button class="btn px-15 btn-primary text-white">
                                    <i class="fas fa-plus fs-16"></i>
                                    Tambah Autoreply
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <AutoreplyTable />
                    </div>
                </div>
            </div>
            <modal name="importExport" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <download-csv ref="exportAutoreply" :data="exportJson" name="whacenter_autoreply.csv"
                        style="display: none"></download-csv>
                    <button v-on:click="exportAutoreply" class="btn btn-primary btn-block">Export Autoreply</button>
                    <hr>
                    <ImportAutoreply :devices="device" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import AutoreplyTable from "@/components/tables/Autoreply.vue";
    import ImportAutoreply from "@/components/forms/autoreply/Import.vue";

    export default {
        components: {
            AutoreplyTable,
            ImportAutoreply
        },
        data() {
            return {
                group_dropdown: [],
                exportJson: [],
                device: []
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
            this.getDevices()
        },
        methods: {
            async getDevices(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            async emptyContact() {
                if (confirm("Semua data kontak akan terhapus, Yakin ?")) {
                    try {
                        let response = await this.$store.dispatch('contact_empty')
                        this.$toast.success(response.data.message);
                        this.$root.$emit("refresh_table", true)
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }
            },
            async exportAutoreply() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('autoreply_export')
                        .then((response) => {
                            let rawData = response.data.data.autoreplys
                            let final = []
                            for (let i = 0; i < rawData.length; i++) {
                                final.push({
                                    "Request": rawData[i].request,
                                    "Text": rawData[i].text,
                                    "File": rawData[i].file,
                                    "Data": rawData[i].data,
                                    "Message Type": rawData[i].message_type,
                                    "Hold": rawData[i].hold,
                                    "isSensitive": rawData[i].sensitive,
                                })
                            }

                            this.exportJson = final
                        })
                        .then(() => {
                            this.$refs.exportAutoreply.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
            fillVar(val) {
                return val
            },
            toggleModal(param) {
                if (this.device.length == 0) this.getDevices()

                this.$modal.toggle(param);
            },
        },
    };
</script>
<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                    <router-link :to="'/autoreply/detail?id=' + props.row.id">
                        <a class="btn btn-primary text-white margin5">Detail</a>
                    </router-link>
                    <a v-on:click="deleteAutoreply(props.row.id)" class="btn btn-danger text-white margin5">
                        Hapus
                    </a>
                </span>
                <span v-else-if="props.column.field == 'sensitive'">
                    <span v-if="props.row.sensitive == '1'" class="badge badge-warning text-white">Sensitive</span>
                    <span v-else-if="props.row.sensitive == '0'" class="badge badge-secondary text-white">Tidak Sensitive</span>
                </span>
                <span v-else-if="props.column.field == 'request'">
                    {{ cutString(props.row.request, 30) }}
                </span>
                <span v-else-if="props.column.field == 'text'">
                    <div>
                        {{ cutString(props.row.text) }}
                        <div v-if="props.row.file">
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <center>
                                        <b>Document</b>
                                        <br>
                                        <a :href="props.row.file" target="_blank"> Open File </a>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Device",
                            field: "device.name",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "15%",
                        },
                        {
                            label: "Request",
                            field: "request",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "15%",
                        },
                        {
                            label: "Response",
                            field: "text",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "40%",
                        },
                        {
                            label: "Mode",
                            field: "sensitive",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        nextKey: null
                    },
                    serverParams2: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        limit: 10,
                    },
                },
                deviceName: {}
            };
        },
        mounted() {
            this.fn = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fn);
            this.loadItems();
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            cutString(string, max = 50){
                try{
                    if(string.length >= max){
                        return string.substring(0, max) + "..."
                    }else{
                        return string
                    }
                }catch(err){
                    return ""
                }
            },
            convertDateToLocal(date){
                let m = moment.utc(date, "YYYY-MM-DD HH:mm:ss")
                let stillUTC = m.local().format("YYYY-MM-DD HH:mm:ss")
                let m2 = moment.utc(stillUTC, "YYYY-MM-DD HH:mm:ss")
                return m2.local().format("YYYY-MM-DD HH:mm:ss")
            },
            async deleteAutoreply(id) {
                try {
                    let response = await this.$store.dispatch("autoreply_delete", {
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                try {
                    let response = await this.getFromServer(this.table.serverParams)
                    this.table.total_records = response.data.data.total_records
                    let autoreply = response.data.data.autoreplys
                    for (let i = 0; i < autoreply.length; i++) {
                        if(autoreply[i].message_type == "button" && autoreply[i].data){
                            let parse = JSON.parse(autoreply[i].data)
                            autoreply[i].parsedButton = parse
                            autoreply[i].footer = parse?.[0]?.footer
                        }else if(autoreply[i].message_type == "list"){
                            let parse = JSON.parse(autoreply[i].data)
                            autoreply[i].parsedList = parse
                        }
                    }
                    this.table.rows = autoreply

                } catch (err) {
                    console.log(err)
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("autoreply_list", param);
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>


<style scoped>
    .thick-border{
        border: 2px solid #6c6c6c !important;
    }
</style>